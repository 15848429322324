import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initCustomSelect} from './modules/form/init-custom-select';
import {initFormValidate} from './modules/form/init-form-validate';

import {initSliderPartners} from './modules/sliders/init-swiper-partners';
import {initIntroSlider} from './modules/sliders/init-swiper-intro';
import {initTabsSlider} from './modules/sliders/init-tabs-slider';
import {initTabs} from './modules/tabs/init-tabs';

import {initMoveTo} from './modules/init-move-to';
import {addMap} from './modules/init-map';
import {initPreloader} from './modules/init-preloader';
import {initAnimate} from './modules/init-animate';
import {getVideo} from './modules/init-video';
import {initBurgerAction, closeMenu, mainNavLinkHandler} from './modules/init-mobile-menu';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initCustomSelect();
    initFormValidate();
    initSliderPartners();
    initIntroSlider();
    initTabs();
    initMoveTo();
    initTabsSlider();
    addMap();
    initPreloader();
    initAnimate();
    closeMenu();
    initBurgerAction();
    mainNavLinkHandler();
    getVideo();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✔️

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
