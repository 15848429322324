// import {ScrollLock} from '../utils/scroll-lock';

const headerMenuButton = document.querySelector('[data-menu-toggle]');
const pageHeader = document.querySelector('.page-header');
const mainNavLinks = document.querySelectorAll('.main-nav__link');
const breakpointLg = window.matchMedia('(min-width:1024px)');

// const scrollLock = new ScrollLock();

const onEscPress = (evt) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && pageHeader.classList.contains('is-active')) {
    evt.preventDefault();
    closeMenu();
    window.removeEventListener('keydown', onEscPress);
  }
};

const openMenu = () => {
  headerMenuButton.ariaPressed = 'true';
  pageHeader.classList.add('is-active');
  // scrollLock.disableScrolling();
  headerMenuButton.classList.add('is-active', 'no-pointer');
  setTimeout(() => {
    headerMenuButton.classList.remove('no-pointer');
  }, 1000);
  window.addEventListener('keydown', onEscPress);
};

const closeMenu = () => {
  headerMenuButton.ariaPressed = 'false';
  pageHeader.classList.remove('is-active');

  headerMenuButton.classList.remove('is-active');
  headerMenuButton.classList.add('no-pointer');
  setTimeout(() => {
    headerMenuButton.classList.remove('no-pointer');
  }, 1200);
};

const closeMenuOnResize = () => {
  if (pageHeader.classList.contains('is-active')) {
    closeMenu();
  }
};

const mainNavLinkHandler = () => {
  mainNavLinks.forEach((item) => {
    item.addEventListener('click', () => {
      setTimeout(() => {
        closeMenu();
      }, 300);
    });
  });
};

const initBurgerAction = () => {
  if (headerMenuButton) {
    breakpointLg.addListener(closeMenuOnResize);
    headerMenuButton.addEventListener('click', () => {
      if (headerMenuButton.ariaPressed === 'true') {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }
};

export {initBurgerAction, closeMenu, mainNavLinkHandler};
