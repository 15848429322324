const tabsSliders = document.querySelectorAll('[data-tabs-slider]');
const breakpointMD = window.matchMedia('(min-width:1024px)');
// const breakpointOneSlide = window.matchMedia('(max-width:550px)');
// eslint-disable-next-line no-unused-vars
let swiper;

const getTemplate = (index, className) => {
  const current = index + 1;
  const numberSlide = current < 10 ? `0${current}` : current;

  return (
    `<button class="${className}" type="button" data-bullet="${numberSlide}" aria-label="Кнопка переключения слайдов">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5276 20.8C10.5581 20.7654 10.5848 20.7347 10.6152 20.6924L11.9905 18.6515C12.1162 18.467 12.219 18.3056 12.341 18.1172L14.8857 14.3275C15.3695 13.5934 15.8533 12.89 16.3333 12.1675C16.1619 12.0752 15.8914 11.983 15.7086 11.91C15.4914 11.8216 15.3048 11.7447 15.08 11.6563L11.6057 10.2534C11.5143 10.215 11.3771 10.1496 11.2743 10.1266C11.3924 9.61922 11.5371 9.10419 11.659 8.60453C11.7886 8.08181 11.9143 7.6129 12.0438 7.0825C12.1124 6.80961 12.8019 4.19602 12.8095 4L7 12.6287L11.4038 14.4121C11.5067 14.4544 11.6133 14.4966 11.7314 14.5428C11.8229 14.5812 11.96 14.6465 12.0629 14.6696C11.9067 15.3422 10.5352 20.5502 10.5276 20.7962V20.8Z" fill="currentColor"/>
      </svg>
    </button>`
  );
};

const initSwiper = (slider) => {
  // eslint-disable-next-line no-undef
  return new Swiper(slider, {
    speed: 600,
    slidesPerView: 2,
    spaceBetween: 16,
    watchOverflow: true,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    allowTouchMove: true,
    pagination: {
      el: '.slider-pagination',
      clickable: true,
      bulletClass: 'slider-pagination__button',
      renderBullet: getTemplate,
      bulletActiveClass: 'is-active',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
      375: {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
      },
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
  });
};


const initTabsSlider = () => {
  if (!tabsSliders) {
    return;
  }

  let swipers = [];

  const breakpointChecker = () => {
    if (breakpointMD.matches) {
      if (swipers) {
        swipers.forEach((item) => {
          item.destroy(true, true);
        });
        swipers = [];
      }
    } else {
      tabsSliders.forEach((item) => {
        swipers.push(initSwiper(item));
      });
    }
  };

  // const sliderCheker = () => {
  //   for (let i = 0; i < tabsSliders.length; i++) {
  //     let slides = tabsSliders[i].querySelectorAll('.swiper-slide');
  //     let paginationSlider = tabsSliders[i].querySelector('.product-slider-pagination');

  //     if (slides.length < 3) {
  //       paginationSlider.classList.add('is-hidden');
  //       if (breakpointOneSlide.matches) {
  //         paginationSlider.classList.remove('is-hidden');
  //       }
  //     }

  //     if (slides.length < 2) {
  //       paginationSlider.classList.add('is-hidden');
  //     }
  //   }
  // };

  breakpointMD.addListener(breakpointChecker);
  breakpointChecker();
  // sliderCheker();
  // window.addEventListener('resize', sliderCheker);
};

export {initTabsSlider};
