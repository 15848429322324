const animationGroup = [...document.querySelectorAll('.animation-group')];

const initAnimate = () => {
  const returnAnimatePoint = (group) => {
    const elTop = group.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    return windowHeight / 1.3 - elTop;
  };

  const trackingScreenBlock = () => {
    if (!animationGroup.length) {
      return;
    }

    animationGroup.forEach((group) => {
      let point = returnAnimatePoint(group);

      if (group.classList.contains('page-footer')) {
        point = window.innerHeight - group.getBoundingClientRect().height / 2 - group.getBoundingClientRect().top;
      }

      if (point > 0 && !group.classList.contains('show')) {
        group.classList.add('show');
      }
    });
  };

  window.addEventListener('scroll', () => {
    trackingScreenBlock();
  });
};

export {initAnimate};
