const initPreloader = () => {
  const preloaderNode = document.querySelector('[data-preloader]');
  if (!preloaderNode) {
    return;
  }

  class Preloader {
    constructor(el) {
      this.el = el;
      this.durationFadeout = this.el.dataset.durationFadein;
      this.durationFadein = this.el.dataset.durationFadeout;

      this.load();
    }

    load() {
      document.body.classList.add('scroll-lock');
      this.el.classList.add('is-loading');

      setTimeout(() => {
        this.hide();
      }, this.durationFadein);
    }

    hide() {
      this.el.classList.add('is-ready');
      setTimeout(() => {
        this.el.classList.add('is-hidden');
        document.body.classList.remove('scroll-lock');
      }, 1000);
    }
  }

  // eslint-disable-next-line no-new
  new Preloader(preloaderNode);
};

export {initPreloader};
