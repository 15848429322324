const videoBlock = document.querySelector('.video-block__image-wrapper');

const getIframe = () => {
  videoBlock.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/doTieTZ1o0M" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
};

const getVideo = () => {
  if (videoBlock) {
    setTimeout(getIframe, 3000);
  }
};

export {getVideo};
