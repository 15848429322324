import MoveTo from '../vendor/move-to';

const initMoveTo = () => {
  const moveTo = new MoveTo({
    tolerance: 99,
    duration: 600,
    easing: 'easeOutQuart',
  });

  const triggers = document.querySelectorAll('[data-link-move]');

  if (triggers) {
    for (let i = 0; i < triggers.length; i++) {
      moveTo.registerTrigger(triggers[i]);
    }

    triggers.forEach((item) => {
      item.addEventListener('click', () => {
        let array = Array.from(triggers);
        let index = array.indexOf(item);

        array.forEach((point, i) => {
          if (i === index) {
            point.classList.add('is-active');
          } else {
            point.classList.remove('is-active');
          }
        });
      });
    });
  }
};

export {
  initMoveTo
};
